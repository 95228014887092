import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { PortadaComponent } from 'src/app/shared/components/portada/portada.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
import { FormasDeContactoDosComponent } from 'src/app/shared/components/formas-de-contacto/formas-de-contacto-dos.component';
import { environment } from 'src/environments/environment';
import { HeaderSectionComponent } from 'src/app/modules/general/components/header-section/header-section.component';
import { BuscadorCursosComponent } from '../../componentes/buscador-cursos/buscador-cursos.component';
@Component({
  selector: 'app-cursos-listado',
  templateUrl: './cursos-listado.component.html',
  styleUrls: ['./cursos-listado.component.scss']
})
export class CursosListadoComponent implements OnInit {
  language: any;
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1!: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2!: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3!: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4!: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5!: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6!: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7!: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8!: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9!: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10!: ViewContainerRef;

  viewContainerRefA(index: number): ViewContainerRef | undefined {
    let viewContainerRefs: ViewContainerRef[] = [
      this.viewContainerRef1,
      this.viewContainerRef2,
      this.viewContainerRef3,
      this.viewContainerRef4,
      this.viewContainerRef5,
      this.viewContainerRef6,
      this.viewContainerRef7,
      this.viewContainerRef8,
      this.viewContainerRef9,
      this.viewContainerRef10,
    ];
    return viewContainerRefs[index - 1];
  }
  infoAreaRecomendada: any
  id: any;
  constructor(private services: GeneralService, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver, public router: ActivatedRoute, public venueService: GeneralService, public title: Title, public meta: Meta) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
    });

  }
 

  ngOnInit(): void {
  }
  
  ngAfterViewInit() {

    const currentUrl = this.route.snapshot.url.join('/');
    this.id = currentUrl.replace(this.language + '/', '');
    this.services.getSimplepage(this.id, this.language).subscribe(res => {
      Object.keys(res.data[0].pages).forEach((key) => {
        var arrayValue = res.data[0].pages;
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(info => {
            this.newComponent(Number(key), arrayValue[key][0].entityType, info.data)
          });
        }
      });
    })
  }

  refBuscador;

  newComponent(index: number, type: string, info: any) {
    switch (type) {
      case 'HeaderSection': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(HeaderSectionComponent);
        let ref2 = (this.viewContainerRefA(index)).createComponent(factory);
        // ref2.location.nativeElement.classList.add('col-lg-' + width);
        ref2.instance.pathMob = info.headerSection.pathMobile;
        ref2.instance.path = info.headerSection.path;
        ref2.instance.language = this.language
        /* ref2.instance.itemCarrouselHeader = info.headerSection.headerSectionItem.slice(0, 6); */

      }
        break;
      case 'Buscador': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorCursosComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        this.refBuscador.instance.data = info;
        this.refBuscador.instance.translateY = 110;
        this.refBuscador.instance.padding = "30px 38.6px 30px 38.6px";
        this.refBuscador.changeDetectorRef.detectChanges();

      }
        break;
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info[0];

      }
        break;
      case "Area": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(AreasDeConocimientoComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        //  ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info[0];
        console.log(info[0])
        ref.instance.fondo = "Fondo-cursos.png";
        ref.instance.fondoMob = "fondo-continua-mobile.jpg";

        ref.instance.cursos = true

      }
        break;
      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        // ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info;
        ref.instance.fondo = "Fondo1.png";
        ref.instance.fondoMob = "fondo-mobile.jpg";
        ref.instance.esCurso = true
        //ref.instance.cursos = true

      }
        break;
      case "AreaRecomendada":
        if (this.refBuscador) {
          this.refBuscador.instance.infoAreaRecomendada = info;
          this.refBuscador.changeDetectorRef.detectChanges();
        }

        break

      /*case 'Primera foto destacada': {
       let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
       let ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.data = info['entityList'][0].entity;
       ref.instance.title = info['entityList'][0].title;
       ref.changeDetectorRef.detectChanges();
     }

       break;
     case 'Simple': {
       let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
       let ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.instance.data = info.entityList[0].entity;
       ref.instance.title = info.entityList[0].title;
       ref.instance.background = background;
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.changeDetectorRef.detectChanges();

     }

       break;
     case 'CustomPublication': {
       let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
       let ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.instance.data = info.publication;
       ref.instance.title = 'Publicaciones';
       ref.instance.background = background;
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.changeDetectorRef.detectChanges();
     }

       break;
     case 'Noticias con resumen': {
       let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
       let ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.instance.data = info.entityList[0].entity;
       ref.instance.title = info.entityList[0].title;
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.changeDetectorRef.detectChanges();
     }

       break;
     case 'Carrousel':
       if (info.entityList[0].entity) {
         let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.instance.background = background;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
       break;
     case 'Cards':
       let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
       let ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.instance.data = info.socialNetwork.socialNetworkItem;
       ref.instance.title = info.socialNetwork.title;
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.changeDetectorRef.detectChanges();
       break;
     case 'Lista de links':
       if (info.entityList[0].entity) {
         const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.background = background;
         ref.changeDetectorRef.detectChanges();
       }
       break;

     case 'Video + Formulario (en ingles)':
       if (info.entityList[0].linkCode) {
         const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
         );
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.linkCode = info.entityList[0].linkCode;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.title = info.entityList[0].title;
         ref.instance.background = background;
         ref.instance.idioma = 'en';
         ref.changeDetectorRef.detectChanges();

       }
       break;
     case 'Video + Formulario (en español)':
       if (info.entityList[0].linkCode) {
         const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
         );
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.linkCode = info.entityList[0].linkCode;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.title = info.entityList[0].title;
         ref.instance.background = background;
         ref.instance.idioma = 'es';
         ref.changeDetectorRef.detectChanges();

       }
       break;
     case 'TextItem': {
       const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
       const ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.instance.data = info.title;
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.i = index;
       ref.changeDetectorRef.detectChanges();

     }
       break;

     case 'CustomForm': {
       const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
       const ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.changeDetectorRef.detectChanges();

     }
       break;
     case 'Diary': {
       const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
       const ref = (this.viewContainerRefA(index)).createComponent(factory);
       if (index == 2) {
         width = 4;
       }
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.data = info.diary[0].diaryItem;
       ref.instance.title = info.diary[0].title;
       ref.instance.width = width;
       ref.changeDetectorRef.detectChanges();

     }
       break;
     case 'CustomStats': {
       const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
       const ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.data = info.CustomStats;
       ref.instance.width = width;
       ref.changeDetectorRef.detectChanges();

     }
       break;
     case 'Cabecera': {
       const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
       const ref = (this.viewContainerRefA(index)).createComponent(factory);
       ref.location.nativeElement.classList.add('col-lg-' + width);
       ref.instance.title = info.entityList[0].title;
       ref.instance.slider = info.entityList[0].entity;
       ref.instance.background = info.entityList[0].background;
       ref.instance.path = info.entityList[0].path;
       ref.instance.width = width;
       ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
       ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
       ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
       ref.changeDetectorRef.detectChanges();

     }
       break;*/
    }






  }




}