import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { Resultado } from 'src/app/shared/models/resultado';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
  count: number;
  pages: any[];
  results: Resultado[] = [];
  numberActive: string;
  spinner = true;
  facultades: any = []
  carreras: any = [
    {
      "id": "posgrado",
      "title": "Posgrado",

    },
    {
      "id": "ingreso",
      "title": "Grado",

    },
    {
      "id": "cursos",
      "title": "Cursos",

    }
  ]
  filter: any = {
    "carrera": ["cursos", "ingreso", "posgrado"],
    "facultades": [],
    "eventosNoticias": true,
    "paginas": true,
  }
  firts = true;
  constructor(private aRouter: ActivatedRoute, private services: GeneralService) {


  }

  ngOnInit(): void {
    if (this.firts) {
      this.services.getAllFacultades().subscribe(res => {
        this.facultades = res.data[0];
        this.filter.facultades = res.data[0].map(e => e.id);
        this.firts = false;
        this.aRouter.params.subscribe(
          (params: Params) => {
            this.filter.search = params.q;

            this.spinner = false; //borrar
            this.buscar(1)
          }
        );
      })
    }

  }


  cambiarPagina(page) {

    this.count = 0;
    this.pages = [];
    this.results = [];
    page = (page == '...') ? (this.numberActive + 1) : page;
    this.buscar(page)
  }
  abortController = new AbortController();

  buscar(page) {
    const abortController = new AbortController();
    // Para cancelar la llamada anterior
    if (this.abortController) {
      this.abortController.abort();
      this.spinner=true;
    }
    this.abortController = abortController;

    this.spinner=true;
    this.services.buscador(this.filter, 1, abortController.signal).then(data => {
      this.count = data.data[0].count;
      this.pages = data.data[0].pages;
      this.numberActive = page;
      this.results = data.data[0].results;
      this.spinner = false;

      for (let indice in this.results) {
        this.results[indice].link = this.format(this.results[indice].link);
        if (this.results[indice].entity === "Carrera") {
          this.results[indice].link = this.results[indice].link.replace(/ /g, "-");
          this.results[indice].link = this.results[indice].link.toLowerCase();
        }

      }
    }).catch(error => {
     // this.spinner = false;
    });

  }

  format(texto) {
    texto = texto.replace(/á/gi, "a");
    texto = texto.replace(/é/gi, "e");
    texto = texto.replace(/í/gi, "i");
    texto = texto.replace(/ó/gi, "o");
    texto = texto.replace(/ú/gi, "u");
    return texto;
  }
  updateFilterMultiple(field: string, value: any) {
    this.filter[field] = value;
    this.buscar(1)
  }
}
