<div class="custom-select-wrapper">
  <div class="custom-select-i">
    <div (click)="toggleDropdown()" [id]="id" class="custom-select-trigger nunito-medium trigger-i"
      [ngClass]="{'black-text': selectedOption?.title || selectedOption}">
      {{ selectedOption?.title || selectedOption || placeholder }}
      <!-- <span class="arrow"><i class="fas fa-chevron-down" style="margin-right: 0px !important"></i></span> -->
      <span class="arrow" *ngIf="colorArrow=='azul'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/flecha-select-azul.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='gris'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/Flechita-gris.png" alt=""></span>
      <span class="arrow" *ngIf="colorArrow=='dorada'"><img style="margin-right: 0px !important"
          src="../../../../assets/img/Flechita-dorada.png" alt=""></span>
    </div>
    <div class="custom-options " *ngIf="dropdownOpen">
      <span (click)="toggleDropdown()" *ngIf=" selectedOption  && selectedOption!='' && placeholder != selectedOption"
        class="custom-option nunito-regular" [class.selected]="placeholder === selectedOption"
        (mouseover)="hoverOption = placeholder" [class.hover]="placeholder === hoverOption">
        {{ placeholderAll ? placeholderAll : placeholder }}
      </span>
      <span *ngIf="allOption" [id]="id" class="custom-option nunito-regular" >
        <input class="form-check-input" type="checkbox" value="0" [checked]="isCheckedAll"
          (change)="onCheckboxChangeAll()">
        Todos
      </span>
      <span [id]="id" class="custom-option nunito-regular" *ngFor="let item of options"
        [class.selected]="item === selectedOption" [class.hover]="item === hoverOption">
        <input class="form-check-input" type="checkbox" [value]="item.id || item" [checked]="isChecked(item.id || item)"
          (change)="onCheckboxChange($event, item.id)">
        {{ item.title || item }}
      </span>
    </div>
  </div>
</div>