import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { CabeceraComponent } from 'src/app/modules/general/components/cabecera/cabecera.component';
import { CardsGeneralDosComponent } from 'src/app/modules/general/components/cards-general-dos/cards-general-dos.component';
import { CardsGeneralComponent } from 'src/app/modules/general/components/cards-general/cards-general.component';
import { CarrouselGeneralComponent } from 'src/app/modules/general/components/carrousel-general/carrousel-general.component';
import { CustomDiaryGeneralComponent } from 'src/app/modules/general/components/custom-diary-general/custom-diary-general.component';
import { CustomStatsGeneralComponent } from 'src/app/modules/general/components/custom-stats-general/custom-stats-general.component';
import { FormContactoComponent } from 'src/app/modules/general/components/form-contacto/form-contacto.component';
import { ListaDeLinksComponent } from 'src/app/modules/general/components/lista-de-links/lista-de-links.component';
import { PostComponent } from 'src/app/modules/general/components/post/post.component';
import { PrimeraFotoDestacadaComponent } from 'src/app/modules/general/components/primera-foto-destacada/primera-foto-destacada.component';
import { TextItemGeneralComponent } from 'src/app/modules/general/components/text-item-general/text-item-general.component';
import { VideoMasFormularioComponent } from 'src/app/modules/general/components/video-mas-formulario/video-mas-formulario.component';

@Component({
  selector: 'app-sedes-posgrados',
  templateUrl: './sedes-posgrados.component.html',
  styleUrls: ['./sedes-posgrados.component.scss']
})
export class SedesPosgradosComponent implements OnInit {
  language = "es";
  sede = "";
  sedeFormater = "";
  datos: any;
  arrayRowsSize: any;
  arrayRows: any[] = [];
  botones: any[] = [];
  title: string;
  path: string;
  pathMob: string;
  innerHTML = '';
  data: any;
  count = 0;
  isMob: boolean
  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private services: GeneralService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.route.params.forEach((params: Params) => {
      this.language = params['language'];
     
    });
    const currentUrl = this.route.snapshot.url.join('/');
    
    let title = currentUrl.replace(this.language + '/', '');
    let sede = currentUrl.replace(this.language + '/posgrados/' , '');

    this.sedeFormater = sede.replace(/-/g, ' ').replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
    this.checkScreenWidth()
    this.services.getSinglePage(title, this.language).subscribe(res => {
      this.datos = res.data[0];
      this.data = this.datos['pages'];
      this.arrayRowsSize = this.datos['pagesLength'];
      for (let i = 1; i < this.arrayRowsSize + 1; i++) {
        if (this.datos['pages'][i][0]['status']) {
          this.arrayRows.push(i);
          this.loadPageAt(i);
        }
      }
    })


  }

  ngOnInit(): void {
  }
  private checkScreenWidth() {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.isMob = true
      } else {
        this.isMob = false
      }

    });
  }
  async loadPageAt(index: number) {
    this.services.getEntities(this.data[index][0]['route'] + '&locale=' + this.language).subscribe(data1 => {
      this.definirComponentes(index, this.data[index][0], 12, data1)
    });
  }
  definirComponentes(index: number, element: any, width: number, data1: any) {
    let info = data1.data[0];
    switch (element.entityType) {
      case "HeaderSection":
        this.title = info.headerSection.title;
        this.pathMob = info.headerSection.pathMobile;
        this.path = info.headerSection.path;
        this.botones = (info.headerSection.headerSectionItem) ? info.headerSection.headerSectionItem.slice(0, 6) : [];
        this.count++;
        break;

      case "TextItem":
        this.newComponent(index, 'TextItem', info, width)

        break;

      case 'EntityList':
        info.entityList.forEach(element => {
          let type = element.typeDisplay;
          var background = element.background;
          this.newComponent(index, type, info, width, background)
        });
    }



  }
  newComponent(index: number, type: string, info: any, width: number, background?: string) {
    switch (type) {
      case 'Primera foto destacada': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PrimeraFotoDestacadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info['entityList'][0].entity;
        ref.instance.title = info['entityList'][0].title;
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Simple': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (width == 8) {
          ref.instance.numberCards = 3;
        }
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'CustomPublication': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.publication;
        ref.instance.title = 'Publicaciones';
        ref.instance.background = background;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Noticias con resumen': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.entityList[0].entity;
        ref.instance.title = info.entityList[0].title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
      }

        break;
      case 'Carrousel':
        if (info.entityList[0].entity) {
          let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
          let ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.changeDetectorRef.detectChanges();
        }
        break;
      case 'Cards':
        let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.socialNetwork.socialNetworkItem;
        ref.instance.title = info.socialNetwork.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();
        break;
      case 'Lista de links':
        if (info.entityList[0].entity) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.data = info.entityList[0].entity;
          ref.instance.title = info.entityList[0].title;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.background = background;
          ref.changeDetectorRef.detectChanges();
        }
        break;

      case 'Video + Formulario (en ingles)':

        //si esto rompe posiblemente se tenga que cambiar de "linkCode" a "link_video" 
        if (info.entityList[0].linkCode) {

          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.idioma = 'en';
          ref.instance.entityId = info.entityList[0].id;
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'Video + Formulario (en español)':
        if (info.entityList[0].linkCode) {
          const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
          );
          const ref = (this.viewContainerRefA(index)).createComponent(factory);
          ref.instance.linkCode = info.entityList[0].linkCode;
          ref.location.nativeElement.classList.add('col-lg-' + width);
          ref.instance.title = info.entityList[0].title;
          ref.instance.background = background;
          ref.instance.entityId = info.entityList[0].id;
          ref.instance.idioma = 'es';
          ref.changeDetectorRef.detectChanges();

        }
        break;
      case 'TextItem': {

        const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info.title;
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.i = index;
        ref.changeDetectorRef.detectChanges();

      }
        break;

      case 'CustomForm': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Diary': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        if (index == 2) {
          width = 4;
        }
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.diary[0].diaryItem;
        ref.instance.title = info.diary[0].title;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'CustomStats': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.data = info.CustomStats;
        ref.instance.width = width;
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'Cabecera': {
        const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
        const ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.location.nativeElement.classList.add('col-lg-' + width);
        ref.instance.title = info.entityList[0].title;
        ref.instance.slider = info.entityList[0].entity;
        ref.instance.background = info.entityList[0].background;
        ref.instance.path = info.entityList[0].path;
        ref.instance.width = width;
        ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
        ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
        ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
        ref.changeDetectorRef.detectChanges();

      }
        break;


    }






  }
  viewContainerRefA(index: number): any {
    switch (index) {
      case 1:
        return this.viewContainerRef1;
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;

        // 
        break;
    }
  }

  url(element: any) {
    let url = '';
    if (element.route) {
      if (element.externalLink) {
        url = element.route;

      } else {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }

    }
    if (element.systemTitle) {
      url = element.systemTitle
      if (element.externalLink) {
        url = element.systemTitle;

      } else {
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/' + this.language + '/' + url;
      }
    }

    return url;
  }
}
