import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-select-multiple',
  templateUrl: './custom-select-multiple.component.html',
  styleUrls: ['./custom-select-multiple.component.scss']
})

export class CustomSelectMultipleComponent {
  @Input() options: any[] = [];
  @Input() placeholder: string = 'Select';
  @Input() placeholderAll: string;
  @Output() selectionChange = new EventEmitter<any>();
  @Input() ingreso: boolean = false
  @Input() id = '';
  @Input() height: any
  @Input() italic: boolean
  @Input() curso: boolean = false
  @Input() colorArrow = "gris"
  @Input() optionPlaceholder: boolean = true
  @Input() values: any[] = []
  @Input() isCheckedAll = true;
  @Input() allOption = false;
  selectedOption: any = '';
  dropdownOpen: boolean = false;
  hoverOption: any = null;
  constructor(private elementRef: ElementRef) {
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onCheckboxChangeAll() {
    if (this.isCheckedAll) {
      this.values = []
      this.isCheckedAll = false;
    } else {
      this.values = this.options.map(value => value.id);
      this.isCheckedAll = true;
    }
    this.selectionChange.emit(this.values)

  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }
  @Input()
  set reset(value: boolean) {
    if (value) {
      this.selectedOption = null;
    }
  }

  isChecked(id: number): boolean {
    return this.values.includes(id);
  }

  // Método para manejar el cambio de estado de los checkboxes
  onCheckboxChange(event: any, id: number): void {
    console.log(event)
    if (event.target.checked) {
      this.values.push(id); // Si está marcado, lo agregamos a 'values'
    } else {
      this.values = this.values.filter(value => value !== id); // Si está desmarcado, lo eliminamos
    }
    this.selectionChange.emit(this.values)
  }
}
