<section class="subheader">
  <img onerror="this.src='./assets/img/default.png'" class="w-100" src="../../../../../assets/img/noticia.jpg">
  <div class="texto-s container">
    <h1 class="blanco">Resultados</h1>
  </div>
</section>

<section class="bussec1">

  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="container-responsive" [style.margin]="'30px 10px'">
          <div class="input-container">
            <h2>Resultados: {{count}} para “{{filter.search}}”</h2>
          </div>
          <div class="input-container">
            <app-custom-select-multiple [colorArrow]="'azul'" placeholderAll="Todas las Carrera" [options]="carreras"
              placeholder="Elegí las Carreras" [values]="filter.carrera"
              (selectionChange)="updateFilterMultiple('carrera', $event)" [ingreso]="true"
              [id]="'select-facultades'">
            </app-custom-select-multiple>
          </div>
          <!--<div class="input-container">
            <app-custom-select-multiple [allOption]="true" [colorArrow]="'azul'" placeholderAll="Todas las Facultades"
              [options]="facultades" placeholder="Elegí las Facultades" [values]="filter.facultades"
              (selectionChange)="updateFilterMultiple('facultades', $event)" [ingreso]="true" [id]="'select-facultades'">
            </app-custom-select-multiple>
          </div>-->

          <div class="input-container">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [(ngModel)]="filter.eventosNoticias" (ngModelChange)="buscar(1)">
              <label class="form-check-label">
                Noticias y  Eventos 
              </label>
            </div>
            <div class="form-check">
              <input  class="form-check-input" type="checkbox" [(ngModel)]="filter.paginas" (ngModelChange)="buscar(1)">
              <label class="form-check-label">
                Paginas
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="d-flex justify-content-center" *ngIf='spinner'>
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="busqueda-cont" *ngIf='!spinner'>

          <div *ngFor="let item of  results" class="busqueda-item">
            <a [href]="item.link">
              <img onerror="this.src='./assets/img/default.png'" [src]="item.image">
              <h5 class="azul">{{item.title}}</h5>
              <span>{{item.updated_at.date | date:'d/M/yy'}}</span>
            </a>
          </div>

        </div>
        <div class="paginacion" *ngIf='!spinner && results.length>0'>

          <div (click)='cambiarPagina(page)' *ngFor="let page of  pages" class="number "
            [ngClass]='(numberActive == page)?"active":""'>{{page}}</div>

        </div>
        <div class="paginacion p-3 text-center" *ngIf='!spinner && results.length==0'>
          <div class="alert alert-primary text-center" role="alert">
            No hay resultados
          </div>
        </div>
      </div>

    </div>
  </div>
</section>