import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CommonModule} from '@angular/common';
import {ShellModule} from '../../modules/shell/shell.module';
import {IvyGalleryModule} from 'angular-gallery';
import {BrowserModule} from '@angular/platform-browser';
import { ModalConsultaComponent } from './modal-consulta/modal-consulta.component';
import { AgendaComponent } from './agenda/agenda.component';
import { SumateANuestraComunidadComponent } from './sumate-a-nuestra-comunidad/sumate-a-nuestra-comunidad.component';
import { CardsNoticiasComponent } from './cards-noticias/cards-noticias.component';
import { SliderCardsComponent } from './slider-cards/slider-cards.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeneralUnoComponent } from './general-uno/general-uno.component';
import { GeneralDosComponent } from './general-dos/general-dos.component';
import { GeneralTresComponent } from './general-tres/general-tres.component';
import { ModalGalleryComponent } from './modal-gallery/modal-gallery.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ContactoComponent } from './contacto/contacto.component';
import { VideoComponent } from './video/video.component';
import { ModalGeneralComponent } from './modal-general/modal-general.component';
import { FormHubspotComponent } from './form-hubspot/form-hubspot.component';
import { IframeComponent } from './iframe/iframe.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { FormasDeContactoDosComponent } from './formas-de-contacto/formas-de-contacto-dos.component';
import { CardOverlayComponent } from './card-overlay/card-overlay.component';
import { DetalleCursoComponent } from './detalle-curso/detalle-curso.component';
import { BuscadorCursosTematicoComponent } from './buscador-cursos-tematico/buscador-cursos-tematico.component';
import { AreasDeConocimientoComponent } from 'src/app/shared/components/areas-de-conocimiento/areas-de-conocimiento.component';
import { CardOverlay2Component } from './card-overlay2/card-overlay2.component';
import { PortadaComponent } from './portada/portada.component';
import { BannerComponent } from './banner/banner.component';
import { CardAreaConocimientoComponent } from './card-area-conocimiento/card-area-conocimiento.component';
import { AreasRecomendadasComponent } from './areas-recomendadas/areas-recomendadas.component';
import { BuscadorPosgradosTematicoComponent } from './buscador-posgrados-tematico/buscador-posgrados-tematico.component';
import { CustomSelectMultipleComponent } from './custom-select-multiple/custom-select-multiple.component';

@NgModule({
  declarations:[  ModalConsultaComponent, AgendaComponent, SumateANuestraComunidadComponent, CardsNoticiasComponent, SliderCardsComponent, GeneralUnoComponent, GeneralDosComponent, GeneralTresComponent, ModalGalleryComponent, ContactoComponent, VideoComponent, ModalGeneralComponent, FormHubspotComponent, IframeComponent, CustomSelectComponent, FormasDeContactoDosComponent, CardOverlayComponent, BannerComponent,  DetalleCursoComponent, BuscadorCursosTematicoComponent, AreasDeConocimientoComponent, CardOverlay2Component, PortadaComponent, CardAreaConocimientoComponent, AreasRecomendadasComponent, BuscadorPosgradosTematicoComponent, CustomSelectMultipleComponent],
  imports:[IvyGalleryModule,CommonModule, ShellModule, MatToolbarModule,FormsModule,BrowserModule,ReactiveFormsModule,AngularMaterialModule],
  providers:[],
  exports:[CustomSelectMultipleComponent,AreasRecomendadasComponent,ModalGeneralComponent,VideoComponent,FormHubspotComponent, ContactoComponent,ModalGalleryComponent,GeneralDosComponent, GeneralUnoComponent,ModalConsultaComponent,AgendaComponent, SumateANuestraComunidadComponent, CardsNoticiasComponent, SliderCardsComponent, CustomSelectComponent, FormasDeContactoDosComponent, CardOverlayComponent, DetalleCursoComponent, BuscadorCursosTematicoComponent, AreasDeConocimientoComponent, CardOverlay2Component, PortadaComponent],
  entryComponents:[]
})
export class SharedModule {
}
